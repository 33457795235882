const routes = [
  {
    path: "/nova-conta/selecao-plano",
    name: "nova-conta-selecao-plano",
    meta: { 
      title: "Seleção de Plano", 
      authRequired: false,
      roles: []
    },
    component: () => import("@/views/nova-conta/selecao-plano")
  },
  {
    path: "/nova-conta/dados-basicos/:planoId?",
    name: "nova-conta-dados-basicos",
    meta: { 
      title: "Dados Básicos", 
      authRequired: false,
      roles: []
    },
    component: () => import("@/views/nova-conta/dados-basicos"),
  },
  {
    path: "/nova-conta/dados-complementares",
    name: "nova-conta-dados-complementares",
    meta: { 
      title: "Dados Complementares", 
      authRequired: false,
      roles: []
    },
    component: () => import("@/views/nova-conta/dados-complementares"),
  },
  {
    path: "/nova-conta/dados-acesso",
    name: "nova-conta-dados-acesso",
    meta: { 
      title: "Dados de Acesso", 
      authRequired: false,
      roles: []
    },
    component: () => import("@/views/nova-conta/dados-acesso"),
  },
  {
    path: "/nova-conta/relacao-imprensa",
    name: "nova-conta-relacao-imprensa",
    meta: { 
      title: "Relação Com a Imprensa", 
      authRequired: false,
      roles: []
    },
    component: () => import("@/views/nova-conta/relacao-imprensa"),
  },
  {
    path: "/nova-conta/material-utilizado",
    name: "nova-conta-material-utilizado",
    meta: { 
      title: "Material Utilizado", 
      authRequired: false,
      roles: []
    },
    component: () => import("@/views/nova-conta/material-utilizado"),
  },
  {
    path: "/nova-conta/termos-uso",
    name: "nova-conta-termos-uso",
    meta: { 
      title: "Termos de Uso", 
      authRequired: false,
      roles: []
    },
    component: () => import("@/views/nova-conta/termo-uso"),
  },
  {
    path: "/nova-conta/agenda-kickoff",
    name: "nova-conta-agenda-kickoff",
    meta: { 
      title: "Onboarding / Agendar o Kick off", 
      authRequired: true,
      roles: []
    },
    component: () => import("@/views/nova-conta/agenda-kickoff")
  },
];
export default routes;

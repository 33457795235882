import Roles from "@/utils/roles"

export default [
  {
    path: "/gestao-clientes",
    name: "Gestão de Clientes",
    component: () => import("@/views/area-mention/gestao-clientes/index.vue"),
    meta: {
      title: 'Gestão de Clientes',
      authRequired: true,
      roles: [Roles.LerTodosClientes, Roles.LerMeusClientes]
    },
  }
];
import { createI18n } from 'vue-i18n';
const currentLocale = () => {
  return 'pt'
}

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('@/lang', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = createI18n({
  legacy: false,
  locale: currentLocale(),
  fallbackLocale: currentLocale(),
  messages: loadLocaleMessages(),
  globalInjection: true,
});

export default i18n

import * as validators from '@vuelidate/validators'
import { isValidCNPJ, isValidCPF } from "./cpfCnpjValidation";
import { helpers } from '@vuelidate/validators';

const { createI18nMessage } = validators
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) })

// wrap each validator.
export const required = withI18nMessage(validators.required, { withArguments: true })
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true })
// wrap each validator.
export const email = withI18nMessage(validators.email)
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
export const url = withI18nMessage(validators.url, { withArguments: true })
export const minValue = withI18nMessage(validators.minValue, { withArguments: true })
export const maxValue = withI18nMessage(validators.maxValue, { withArguments: true })
//export 
//helpers.withMessage('This field cannot be empty', required)


export const cpfCnpjValidator = helpers.withMessage(
  'CPF/CNPJ inválido',
  (value) => {
    let num = String(value ?? value.trim()).replace(/[^\d]/g, "");
    if (num.length != 11 && num.length != 14) {
      return true;
    }
    return num.length == 11 ? isValidCPF(num) : isValidCNPJ(num)
  }
);


const sameAsCustom = withI18nMessage(
  validators.sameAs,
  {
    withArguments: true,
    messagePath: ({ $params }) => i18n.global.t('validations.sameAs', [$params.otherName])
  }
)
export const sameAs = (name, other) => validators.helpers.withParams({ type: 'sameAs' }, sameAsCustom(other, name))

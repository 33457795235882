export default {
    install(app) {
      app.directive('highlight', {
        mounted(el, binding) {
          const highlightText = (searchValue) => {
            const content = el.textContent;
            if (searchValue) {
              const highlighted = content.replace(new RegExp(searchValue, 'gi'), (match) => `<span class="highlight">${match}</span>`);
              el.innerHTML = highlighted;
            } else {
              el.innerHTML = content;
            }
          };
          highlightText(binding.value);
  
          el.__highlightHandler__ = (newVal) => {
            highlightText(newVal);
          };
        },
        updated(el, binding) {
          el.__highlightHandler__(binding.value);
        }
      });
    }
  };
  
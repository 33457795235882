import Roles from "@/utils/roles"

export default [
  {
    path: "/envio-emails",
    name: "envio-emails",
    component: () => import("@/views/area-mention/envio-emails/index.vue"),
    meta: {
      title: 'Envio de e-mails',
      authRequired: true,
      roles: [Roles.EnvioEmails]
    }
  },
  {
    path: "/envio-emails/editar/:id",
    name: "editar-email",
    component: () => import("@/views/area-mention/envio-emails/form.vue"),
    meta: {
      title: 'Envio de e-mails',
      authRequired: true,
      roles: [Roles.EnvioEmails]
    }
  },
  {
    path: "/envio-emails/novo-email",
    name: "novo-email",
    component: () => import("@/views/area-mention/envio-emails/form.vue"),
    meta: {
      title: 'Envio de e-mails',
      authRequired: true,
      roles: [Roles.EnvioEmails]
    }
  }
];